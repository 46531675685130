export class Entity {
    constructor (
        public idEntity: number,
        public idEntityModel: number,
        public idDataloggerSoftware: number,
        public serialNumber: number,
        public name: string,
        public manufactureYear: number,
        public mongoDB: string,
        public dataPath: string,
        public licenseStart: any,
        public entityInfo: {
            location: { lat: string, lng: string },
            hourlyPrice: { hourlyPrice: number},
            oeeObjective: { oeeObjective: number},
            updateDataEdge?: {update: boolean, date: string},
            connectivity?: {text: boolean, color: string}
            lastCheck?: number,
            lastCheckFormatted?: string
        },
        public nameSpace: string,
        public tags: string,
        public warrantyEnd: string
     ){}
}
