import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
//import { Router } from '@angular/router';
import { MatFormFieldModule,
         MatInputModule,
         MatCardModule,
         MatProgressSpinnerModule,
         MatButtonModule
        }                    from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CustomReuseStrategy } from './customReuseStrategy';

import { environment }      from './../environments/environment';
import { AppComponent }     from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard }        from './auth-guard.service';
import { AlertService }     from './alert.service';
import { AuthService }      from './auth.service';
import { LoginComponent }   from './login/login.component';
import { LoginUserComponent } from './login-user/login-user.component';
import { FooterComponent }  from './footer/footer.component';
import { MainModule }       from './main/main.module';
import * as process from 'process';
window['process'] = process;
// import * as Raven from 'raven-js';
import { QueryStore } from './main/shared/store/query.store';
import { TabStore } from './main/shared/store/tab.store';
import { HttpConfigInterceptor } from './interceptors/http-interceptor';
import { ErrorHttpConfigInterceptor } from './interceptors/error-http-interceptor';
// export function httpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
// }

// if (environment.sentry) {
//   Raven
//     .config('https://b54c0edf15b742c99bba25dcb483e870@sentry.io/280073')
//     .install();
// }

export class VixionErrorHandler implements ErrorHandler {
  handleError(err:any) : void {
    if (!environment.production) {
        // console.log(err)
    }
    // Raven.captureException(err);
    // process.stderr.write(err);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginUserComponent,
    FooterComponent,
  ],
  imports: [
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSnackBarModule,
    MainModule,
    HttpClientModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: VixionErrorHandler },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    //{ provide: RouteReuseStrategy, useClass: CustomReuseStrategy }, //maintain previous model when navigating back
    AuthGuard,
    AuthService,
    AlertService,
    QueryStore,
    TabStore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHttpConfigInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  //constructor(router: Router) {
    //console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  //}
}
