import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Account }      from './account';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class AccountService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all accounts
   */
  
  public getAccounts(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Account[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Account[]>(environment.api_url + '/accounts', { params: queryParams });
  };
  /**
   * Retrieves account details by a given id
   *
   * @param idAccount
   */
  public getAccount(idAccount: string): Observable<Account> { 
    return this.http.get<Account>(environment.api_url + "/account/" + idAccount); 
  };
  /**
   * Save account
   *
   * @param account
   */
  public saveAccount(account: Account): Observable<any> {
    if (account.idAccount) {
      return this.updateAccount(account);
    } else {
      return this.newAccount(account);
    }
    //return null;
  };

  /**
   * Create new account
   *
   * @param account
   */

  public newAccount(account: Account): Observable<any> { 
    return this.http.post(environment.api_url + "/account", account);
  };

  /**
   * Update account
   *
   * @param account
   */

  public updateAccount(account: Account): Observable<any> { 
    return this.http.put(environment.api_url + "/account", account);
  };
  /**
   * Delete an account by a given id
   *
   * @param idAccount
   */

  public removeAccount(idAccount: number): Observable<any> { 
    return this.http.delete(environment.api_url + "/account/" + idAccount);
  };

  /**
   * Retrieves accounts that can be notificated by entity
   *
   * @param idEntity
   */

  public getNotificableAccountsByEntity(idEntity: string): Observable<Account> {
    return this.http.get<Account>(environment.api_url + "/account/notification/entity/" + idEntity);
  }

  /**
   * Retrieves accounts by a given entity id
   *
   * @param idEntity
   */
  
  public getEntityAccounts(idEntity: string): Observable<Account[]> { 
    return this.http.get<Account[]>(environment.api_url + "/account/entity/" + idEntity);
  };
  /**
   * Retrieves accounts by a given organization id
   *
   * @param idorganization
   */
  public getOrgAccounts(idorganization: string): Observable<Account[]> { 
    return this.http.get<Account[]>(environment.api_url + "/accounts/singleOrganization/" + idorganization)
  };

}
