import { Location } from "@angular/common";
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator, MatSort, SortDirection } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import {MatTable} from '@angular/material';

import { environment } from "../../../../environments/environment";
import * as _ from 'lodash';
import * as moment from 'moment';

declare var _:any;

@Component({
  selector: 'tableList',
  templateUrl: './tableList.component.html',
  styleUrls: ['./tableList.component.css']
})
export class TableListComponent implements OnInit, OnChanges {
  constructor(
    public dialog2: MatDialog,
    private _location: Location
  ) { }

  @Input() module: string;
  @Input() table_headers: Array<string>;
  @Input() fields: Array<any>;
  @Input() data: any[];
  @Input() canCreate: boolean;
  @Input() canDelete: boolean;
  @Input() loading: boolean;
  @Input() menuTitle: string;
  @Input() hideBackButton: boolean = true;
  @Input() insideEntity: boolean = false;
  @Input() insideLicense: boolean = false;
  @Input() csvButton: boolean = false;

  @Output() tableListEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  _fields: any[] = [];
  idFields: string[] = [];
  dataSource: MatTableDataSource<any>;
  filter: any = { 'entityInfo.connectivity': { connectivity_color: '', connectivity_text: '' } };
  cardHeaderColor = environment.card_header_color;

  ngOnInit() {
    if (this.fields && this.fields.length > 0) {
      this._fields = this.fields;
      this.idFields = this._fields.map(value => value.id);
    }
    this.initializeDataSource();
  }

  prevent(e: any) {
    e.preventDefault();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.initializeDataSource();
    }
  }

  initializeDataSource() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.table.renderRows();

    // Custom filter predicate
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      let searchTerms = JSON.parse(filter);
      let isMatch = true;
      Object.keys(searchTerms).forEach(key => {
        if (searchTerms[key]) {
          let dataValue = this.getValue(data, key);
          if (dataValue == null) {
            isMatch = false;
          } else if (Array.isArray(dataValue)) {
            // Si es un array, verificar si algún elemento coincide
            const arrayMatch = dataValue.some(item => {
              if (typeof item === 'object') {
                // Si los elementos son objetos, podemos especificar qué propiedad usar para filtrar
                if (key === 'activeApps') {
                  // Para 'activeApps', filtrar por el 'name' de las apps
                  return item.name && item.name.toLowerCase().includes(searchTerms[key].toLowerCase());
                } else {
                  // Para otros arrays de objetos, convertir a string y verificar
                  const itemString = JSON.stringify(item).toLowerCase();
                  return itemString.includes(searchTerms[key].toLowerCase());
                }
              } else {
                return item && item.toString().toLowerCase().includes(searchTerms[key].toLowerCase());
              }
            });
            if (!arrayMatch) {
              isMatch = false;
            }
          } else if (typeof dataValue === 'object') {
            if (key === 'entityInfo.connectivity') {
              if(searchTerms[key]['connectivity_color']) {
                if (dataValue['colorText'] !== searchTerms[key]['connectivity_color']) {
                  isMatch = false;
                }
              } 
            } else {
              // Si es un objeto, convertir a string y verificar
              const objectString = JSON.stringify(dataValue).toLowerCase();
              if (!objectString.includes(searchTerms[key].toLowerCase())) {
                isMatch = false;
              }
            }
          } else {
            // Si es un valor primitivo, comparar directamente
            if (!dataValue.toString().toLowerCase().includes(searchTerms[key].toLowerCase())) {
              isMatch = false;
            }
          }
        }
      });
      return isMatch;
    };

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string | number => {
      // Usamos la función getValue para acceder al valor de la columna
      let value = this.getValue(data, sortHeaderId);
      console.log('value', value);
      if (sortHeaderId === 'entityInfo.lastCheckFormatted') {
        // Si el valor es una fecha en formato string, conviértela a timestamp
        if (value) {
          return moment(value, 'DD/MM/YYYY HH:mm:ss').unix();
        } else {
          return 0;
        }
      }

      if (sortHeaderId === 'entityInfo.connectivity') {
        let colorOrder = value.colorOrder;
        let text = value.text;
        return colorOrder + '_' + text.toLowerCase();
      } 
    
      if (value == null) {
        return '';
      }
    
      // Si el valor es un objeto o un array, necesitamos transformarlo en una cadena
      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          // Si es un array, podemos concatenar los valores
          return value.join(' ');
        } else {
          // Si es un objeto, podemos convertirlo a cadena JSON
          return JSON.stringify(value);
        }
      }
    
      // Retornamos el valor como cadena o número
      return value.toString();
    };
  }

  applyFilter(field: string, value: string, type: string = null) {
    if(type == 'connectivity_color') {
      this.filter[field]['connectivity_color'] = value.trim().toLowerCase() || '';
    } else {
      this.filter[field] = value.trim().toLowerCase() || '';
    }
    this.dataSource.filter = JSON.stringify(this.filter);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  capitalizeFirstLetter(string) {
    //Capitalize first letter of a string
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  lowerCaseFirstLetter(string) {
    //Lowerize first letter of a string
    if (string != "Vixion Edge" && string != "OEM" && string != "VPN IP") {
      return string.charAt(0).toLowerCase() + string.slice(1);
    }
    else {
      return string;
    }
  }

  onSort() {
    // Sorting is handled automatically by MatTableDataSource
    // this.getData();
  }

  onNew() {
    this.tableListEmitter.emit({text: 'new'});
  }

  onCsv() {
    this.tableListEmitter.emit({text: 'csv'});
  }

  onSelect(event: any, data: any){
    this.tableListEmitter.emit({text: 'select', data: data});
  }

  onRefresh(event: any){
    this.tableListEmitter.emit({text: 'refresh'});
  }

  onEdit(event: any, data: any){
    if (event.stopPropagation) event.stopPropagation();
    this.tableListEmitter.emit({text: 'edit', data: data});
  }

  getValue(value: Object, field: string){
    //if(field == 'entities') {debugger}
    return _.get(value, field);;
  }

  isEvaluationMode(value: Object, field: string){
    return _.get(value, field).evaluationMode;
  }

  combo() {
    this.tableListEmitter.emit({text: 'combo'});
  }

  openDialogDelete(event: any): void {
    event.stopPropagation();
    let dialogRef = this.dialog2.open(DialogComponent3, {
          data: {
            tittle: "Delete",
            body: "Are you sure?"
          }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.tableListEmitter.emit({text: 'remove'});
      }

    });
  }

  openDialog(event: any, data: any): void {
    event.stopPropagation();
    let dialogRef = this.dialog2.open(DialogComponent3,{
        data: { tittle: "Unassign machine from this license",
                body: "Take care that if you unassing this machine from the license, Vixion App will not work for the machine. Are you sure?"
              }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.tableListEmitter.emit({text: 'unassignEntityToLicense', data: data});
        // this.onUnassignEntityToLicense(data)
      }

    });
  }

  onBack(){
    this._location.back();
  }
}

// //DIALOG COMPONENT
//
@Component({
  selector: '[dialogtest]',
  template: `
    <h2 mat-dialog-title>{{ data.tittle }}</h2>
    <mat-dialog-content> {{ data.body }} </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">No</button>
      <button mat-button [mat-dialog-close]="true">Yes</button>
    </mat-dialog-actions>`
})
export class DialogComponent3 {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent3>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

export interface Response {
  rows: any[];
  count: number;
}
