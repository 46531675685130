import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard }       from './../auth-guard.service';
import { MainComponent }   from './main.component';

import { Angulartics2RouterlessModule } from 'angulartics2/routerlessmodule';

const mainRoutes: Routes = [
  { path: '', component: MainComponent,
    children: [
    {
      path: 'accounts',
      loadChildren: () => import('app/main/accounts/accounts.module').then(m => m.AccountsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'greasingTypes',
      loadChildren: () => import('app/main/greasingTypes/greasingTypes.module').then(m => m.GreasingTypesModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'axes',
      loadChildren: () => import('app/main/axes/axes.module').then(m => m.AxesModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'thresholds',
      loadChildren: () => import('app/main/thresholds/thresholds.module').then(m => m.ThresholdsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'entities',
      loadChildren: () => import('app/main/entities/entities.module').then(m => m.EntitiesModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'fleet',
      loadChildren: () => import('app/main/entities-control/entities-control.module').then(m => m.EntitiesControlModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'machine-availability',
      loadChildren: () => import('app/main/machine-availability/machine-availability.module').then(m => m.MachineAvailabilityModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'entityModels',
      loadChildren: () => import('app/main/entityModels/entityModels.module').then(m => m.EntityModelsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'roles',
      loadChildren: () => import('app/main/roles/roles.module').then(m => m.RolesModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'permissions',
      loadChildren: () => import('app/main/permissions/permissions.module').then(m => m.PermissionsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'organizations',
      loadChildren: () => import('app/main/organizations/organizations.module').then(m => m.OrganizationsModule),
      canLoad: [AuthGuard]
    },
    { path: 'settings',
      loadChildren: () => import('app/main/settings/settings.module').then(m => m.SettingsModule),
      canLoad: [AuthGuard]
    },
    { path: 'operatingModes',
      loadChildren: () => import('app/main/operatingModes/operatingModes.module').then(m => m.OperatingModesModule),
      canLoad: [AuthGuard]
    },
    { path: 'apps',
      loadChildren: () => import('app/main/apps/apps.module').then(m => m.AppsModule),
      canLoad: [AuthGuard]
    },
    { path: 'dataloggerHardwares',
      loadChildren: () => import('app/main/dataloggerHardwares/dataloggerHardwares.module').then(m => m.DataloggerHardwaresModule),
      canLoad: [AuthGuard]
    },
    { path: 'dataloggerHardwareModels',
      loadChildren: () => import('app/main/dataloggerHardwareModels/dataloggerHardwareModels.module').then(m => m.DataloggerHardwareModelsModule),
      canLoad: [AuthGuard]
    },
    { path: 'dataloggerSoftwares',
      loadChildren: () => import('app/main/dataloggerSoftwares/dataloggerSoftwares.module').then(m => m.DataloggerSoftwaresModule),
      canLoad: [AuthGuard]
    },
    { path: 'datasources',
      loadChildren: () => import('app/main/datasources/datasources.module').then(m => m.DatasourcesModule),
      canLoad: [AuthGuard]
    },
    { path: 'shifts',
      loadChildren: () => import('app/main/shifts/shifts.module').then(m => m.ShiftsModule),
      canLoad: [AuthGuard]
    },
    { path: 'datasourceModels',
      loadChildren: () => import('app/main/datasourceModels/datasourceModels.module').then(m => m.DatasourceModelsModule),
      canLoad: [AuthGuard]
    },
    { path: 'masterVariables',
      loadChildren: () => import('app/main/masterVariables/masterVariables.module').then(m => m.MasterVariablesModule),
      canLoad: [AuthGuard]
    },
    { path: 'mapVariables',
    loadChildren: () => import('app/main/mapVariables/mapVariables.module').then(m => m.MapVariablesModule),
      canLoad: [AuthGuard]
    },
    { path: 'bbjVariables',
      loadChildren: () => import('app/main/bbjVariables/bbjVariables.module').then(m => m.BBJVariablesModule),
      canLoad: [AuthGuard]
    },
    { path: 'masterSettings',
      loadChildren: () => import('app/main/masterSettings/masterSettings.module').then(m => m.MasterSettingsModule),
      canLoad: [AuthGuard]
    },
    { path: 'variableTypes',
      loadChildren: () => import('app/main/variableTypes/variableTypes.module').then(m => m.VariableTypesModule),
      canLoad: [AuthGuard]
    },
    { path: 'PMCSelectors',
      loadChildren: () => import('app/main/PMCSelectors/PMCSelectors.module').then(m => m.PMCSelectorsModule),
      canLoad: [AuthGuard]
    },
    { path: 'licenses',
      loadChildren: () => import('app/main/licenses/licenses.module').then(m => m.LicensesModule),
      canLoad: [AuthGuard],
    },
    { path: 'swagger',
      loadChildren: () => import('app/main/swagger/swagger.module').then(m => m.SwaggerModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'masterAddons',
      loadChildren: () => import('app/main/masterAddons/masterAddons.module').then(m => m.MasterAddonsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'addonModels',
      loadChildren: () => import('app/main/addonModels/addonModels.module').then(m => m.AddonModelsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'addons',
      loadChildren: () => import('app/main/addons/addons.module').then(m => m.AddonsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'aggregatedSignals',
      loadChildren: () => import('app/main/aggregatedSignals/aggregatedSignals.module').then(m => m.AggregatedSignalsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'notificationRules',
      loadChildren: () => import('app/main/notificationRules/notificationRules.module').then(m => m.NotificationRulesModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'reports',
      loadChildren: () => import('app/main/reports/reports.module').then(m => m.ReportsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'geometricReports',
      loadChildren: () => import('app/main/geometricReports/geometricReports.module').then(m => m.GeometricReportsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'cycleReports',
      loadChildren: () => import('app/main/reports/cycle-report-generator/cycle-report-generator.module').then(m => m.CycleReportGeneratorModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'hydraulicReports',
      loadChildren: () => import('app/main/hydraulicReports/hydraulicReports.module').then(m => m.HydraulicReportsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'heads',
      loadChildren: () => import('app/main/headReports/headReports.module').then(m => m.HeadReportsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'thermals',
      loadChildren: () => import('app/main/thermalReports/thermalReports.module').then(m => m.ThermalReportsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'eulas',
      loadChildren: () => import('app/main/eulas/eulas.module').then(m => m.EULAsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'eula',
      loadChildren: () => import('app/main/eula/eula.module').then(m => m.EULAModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'alarms',
      loadChildren: () => import('app/main/alarms/alarms.module').then(m => m.AlarmsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'incidentCauses',
      loadChildren: () => import('app/main/incidentCauses/incidentCauses.module').then(m => m.IncidentCausesModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'widgets',
      loadChildren: () => import('app/main/widgets/widgets.module').then(m => m.WidgetsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'widgetGroups',
      loadChildren: () => import('app/main/widgetGroups/widgetGroups.module').then(m => m.WidgetGroupsModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'views',
      loadChildren: () => import('app/main/views/views.module').then(m => m.ViewsModule),
      canLoad: [AuthGuard]
    },
    { path: '',
      redirectTo: '/accounts',
      pathMatch: 'full',
      canLoad: [AuthGuard]
    }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(mainRoutes),
    Angulartics2RouterlessModule.forRoot(),
  ],
  exports: [RouterModule]
})

export class MainRoutingModule { }
