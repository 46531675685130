import { CommonModule } from "@angular/common";
import { NgSwitch } from '@angular/common';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import {animate, state, style, transition, trigger} from '@angular/animations';
//import { Observable, of as observableOf} from 'rxjs';

declare var _:any;

/**
 * @title Table retrieving data through HTTP
 */
@Component({
  selector: 'expandableTableListHttp',
  styleUrls: ['./expandableTableListHttp.component.css'],
  templateUrl: './expandableTableListHttp.component.html',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class ExpandableTableListHttpComponent implements OnInit {

  @Input() module: string;
  @Input() table_headers: Array<string>;
  @Input() fields: Array<any>;
  @Input() data: Response;
  @Input() canCreate: boolean;
  @Input() loading: boolean;
  @Input() menuTitle: string;
  @Input() query: any;
  @Input() insideEntity: boolean =false;
  @Input() csvButton: boolean =false;

  @Output() tableListEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  filter: any = {};
  idFields: string[] = [];
  bbjVariables4DeleteObject: any[] = [];
  bbjVariables4DeleteId: number[] = [];
  expandedElement: any;
  ngOnInit() {

    this.idFields = this.fields.map(value => value.id);
    this.expandedElement;
    //initial paginator default values
    this.paginator.pageIndex = this.query.pageIndex || 0;
    this.paginator.pageSize = this.query.pageSize || 10;
    this.paginator.pageSizeOptions = this.query.pageSizeOptions || [5, 10, 20];

    this.sort.active = this.query.sort;
    this.sort.direction = this.query.direction;
    this.filter = this.query.filter || {};

    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    // If the user changes the paginator, refresh data.
    this.paginator.page.subscribe(() => this.getData())
  }

  prevent(e: any) {
    e.preventDefault();
  }

  applyFilter(field: string, value: string) {
    //organization filter exception
    if(field.split('.').pop() == 'organization') {
      field = field.concat('.name');
    }

    this.filter[field] = value;
    this.paginator.pageIndex = 0;
    this.getData();
  }

  getData(){
    //organization sort exception
    let undo = false;
    if(_.endsWith(this.sort.active, 'organization')) {
      this.sort.active = this.sort.active.concat('.name');
      undo = true;
    }

    let query = {pageSize: this.paginator.pageSize, pageIndex: this.paginator.pageSize * this.paginator.pageIndex, sort: this.sort.active, direction: this.sort.direction, filter: this.filter}
    this.tableListEmitter.emit({text: 'getData', data: query});

    //undo organization sort exception
    if(undo) {
      this.sort.active = this.sort.active.split(".").slice(0, -1).join(".");
    }
  }


  capitalizeFirstLetter(string) {
    //Capitalize first letter of a string
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  lowerCaseFirstLetter(string) {
    //Lowerize first letter of a string
    if (string != "Vixion Edge" && string != "OEM" && string != "VPN IP") {
      return string.charAt(0).toLowerCase() + string.slice(1);
    }
    else {
      return string;
    }
  }

  clickDelete(event, variable) {
      event.stopPropagation();
      if (!_.includes(this.bbjVariables4DeleteId, variable.idBBJVariable)) {
        this.bbjVariables4DeleteId.push(variable.idBBJVariable);
        this.bbjVariables4DeleteObject.push(variable);
      } else {
        _.remove(this.bbjVariables4DeleteId, item => item === variable.idBBJVariable);
        _.remove(this.bbjVariables4DeleteObject, item => item === variable);
      }
      // console.log(this.bbjVariables4DeleteObject);
      // console.log(this.bbjVariables4DeleteId);
      // idBJJVariable;
      return;
  }

  onBulkDelete(event: any, data: any) {
    this.tableListEmitter.emit({text: 'bulkDelete', data: data});
    // this.getData();
  }

  onSort() {
    this.getData();
  }

  onNew() {
    this.tableListEmitter.emit({text: 'new'});
  }

  onCsv() {
    this.tableListEmitter.emit({text: 'csv'});
  }

  onSelect(event: any, data: any){
    this.tableListEmitter.emit({text: 'select', data: data});
  }

  onRefresh(event: any){
    this.tableListEmitter.emit({text: 'refresh'});
  }

  viewDetail() {
    this.tableListEmitter.emit({text: 'viewDetail'});
  }

  onEdit(event: any, data: any){
    if (event.stopPropagation) event.stopPropagation();
    this.tableListEmitter.emit({text: 'edit', data: data});
  }

  getValue(value: Object, field: string){
    //if(field == 'entities') {debugger}
    return _.get(value, field);
  }

  getValueRealTime(value: Object){
    if (value["units"]) {
      return _.get(value["realTime"], 'data.sample.value') + ' ' + value["units"];
    }
    else {
        return _.get(value["realTime"], 'data.sample.value') ;
    }
  }

  checkedValidation(variable){
    if (!_.includes(this.bbjVariables4DeleteObject, variable)) {
      return false;
    } else {
      return true;
    }
  }

  getArray(array: any[]){
    array.map( value => {
      console.log(value.id);
      value.id;
    })
  }
}

export interface Response {
  rows: any[];
  count: number;
}
